'use client'

import { TooltipPortal } from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import React, { ReactNode, useState } from 'react'

import { baiJamjuree } from './AppSetup'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip'

interface TooltipProps {
  children: ReactNode
  width?: string
  content: ReactNode
  padding?: string
  textSize?: string
}

export const EthenaTooltip: React.FC<TooltipProps> = ({
  children,
  content,
  padding = 'p-2',
  textSize = 'text-xs',
  width = 'w-[200px]',
}) => {
  const [open, setOpen] = useState(false)

  return (
    <TooltipProvider delayDuration={400}>
      <Tooltip open={open} onOpenChange={setOpen}>
        <TooltipTrigger
          onClick={() => {
            setOpen(!open)
          }}
          onFocus={() => {
            setTimeout(() => setOpen(true), 0)
          }}
          onBlur={() => {
            setOpen(false)
          }}
        >
          {children}
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent className={clsx('z-[9999]', baiJamjuree.className, width, padding, textSize)}>
            {content}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}
